import axios from '@/common/axios'
import qs from 'qs'

export function addDeviceUse (data) {
  return axios({
    method: 'post',
    url: '/universal/device/add',
    data: qs.stringify(data)
  })
}

export function deleteDeviceUse (id) {
  return axios({
    method: 'delete',
    url: '/universal/device/delete/' + id
  })
}

export function updateDeviceUse (data) {
  return axios({
    method: 'put',
    url: '/universal/device/update',
    data: qs.stringify(data)
  })
}

export function selectDeviceUseInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/device/info/' + id
  })
}

export function selectDeviceUseList (query) {
  return axios({
    method: 'get',
    url: '/universal/device/list',
    params: query
  })
}
