<template>
  <div id="deviceUse">
    <el-dialog
      :title="deviceUseFormTitle"
      width="680px"
      :visible.sync="deviceUseDialogVisible"
      :close-on-click-modal="false"
      @close="deviceUseDialogClose"
    >
      <el-form
        ref="deviceUseFormRef"
        :model="deviceUseForm"
        :rules="deviceUseFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="使用部门" prop="useDept">
              <el-input v-model="deviceUseForm.useDept" placeholder="请输入使用部门" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仪器设备名称" prop="deviceName">
              <el-input v-model="deviceUseForm.deviceName" placeholder="请输入仪器设备名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仪器设备型号" prop="deviceModel">
              <el-input v-model="deviceUseForm.deviceModel" placeholder="请输入仪器设备型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仪器设备编号" prop="deviceNo">
              <el-input v-model="deviceUseForm.deviceNo" placeholder="请输入仪器设备编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="useDate">
              <el-date-picker v-model="deviceUseForm.useDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开机时间" prop="startupTime">
              <el-date-picker v-model="deviceUseForm.startupTime" placeholder="请选择开机时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="关机时间" prop="shutdownTime">
              <el-date-picker v-model="deviceUseForm.shutdownTime" placeholder="请选择关机时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运行情况" prop="runningCondition">
              <el-input v-model="deviceUseForm.runningCondition" placeholder="请输入运行情况" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用于产品信息" prop="productInfo">
              <el-input v-model="deviceUseForm.productInfo" placeholder="请输入用于产品信息" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作者" prop="operator">
              <el-input v-model="deviceUseForm.operator" placeholder="请输入操作者" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="deviceUseForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="deviceUseDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deviceUseFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="仪器设备名称">
        <el-input v-model="searchForm.deviceName" placeholder="请输入仪器设备名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="deviceUsePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="useDept" label="使用部门" />
      <el-table-column prop="deviceName" label="仪器设备名称" />
      <el-table-column prop="deviceModel" label="仪器设备型号" />
      <el-table-column prop="deviceNo" label="仪器设备编号" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.useDate">{{ scope.row.useDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开机时间">
        <template slot-scope="scope">
          <span v-if="scope.row.startupTime">{{ scope.row.startupTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="关机时间">
        <template slot-scope="scope">
          <span v-if="scope.row.shutdownTime">{{ scope.row.shutdownTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="runningCondition" label="运行情况" />
      <el-table-column prop="productInfo" label="用于产品信息" />
      <el-table-column prop="operator" label="操作者" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="deviceUsePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addDeviceUse, deleteDeviceUse, updateDeviceUse, selectDeviceUseInfo, selectDeviceUseList } from '@/api/universal/deviceUse'

export default {
  data () {
    return {
      deviceUseDialogVisible: false,
      deviceUseFormTitle: '',
      deviceUseForm: {
        id: '',
        useDept: '',
        deviceName: '',
        deviceModel: '',
        deviceNo: '',
        useDate: '',
        startupTime: '',
        shutdownTime: '',
        runningCondition: '',
        productInfo: '',
        operator: '',
        remarks: ''
      },
      deviceUseFormRules: {
        deviceName: [{ required: true, message: '仪器设备名称不能为空', trigger: ['blur', 'change']}]
      },
      deviceUsePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceName: ''
      }
    }
  },
  created () {
    selectDeviceUseList(this.searchForm).then(res => {
      this.deviceUsePage = res
    })
  },
  methods: {
    deviceUseDialogClose () {
      this.$refs.deviceUseFormRef.resetFields()
    },
    deviceUseFormSubmit () {
      if (this.deviceUseFormTitle === '仪器设备使用记录详情') {
        this.deviceUseDialogVisible = false
        return
      }
      this.$refs.deviceUseFormRef.validate(async valid => {
        if (valid) {
          if (this.deviceUseFormTitle === '新增仪器设备使用记录') {
            await addDeviceUse(this.deviceUseForm)
          } else if (this.deviceUseFormTitle === '修改仪器设备使用记录') {
            await updateDeviceUse(this.deviceUseForm)
          }
          this.deviceUsePage = await selectDeviceUseList(this.searchForm)
          this.deviceUseDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.deviceUseFormTitle = '新增仪器设备使用记录'
      this.deviceUseDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDeviceUse(row.id)
        if (this.deviceUsePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.deviceUsePage = await selectDeviceUseList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.deviceUseFormTitle = '修改仪器设备使用记录'
      this.deviceUseDialogVisible = true
      this.selectDeviceUseInfoById(row.id)
    },
    handleInfo (index, row) {
      this.deviceUseFormTitle = '仪器设备使用记录详情'
      this.deviceUseDialogVisible = true
      this.selectDeviceUseInfoById(row.id)
    },
    selectDeviceUseInfoById (id) {
      selectDeviceUseInfo(id).then(res => {
        this.deviceUseForm.id = res.id
        this.deviceUseForm.useDept = res.useDept
        this.deviceUseForm.deviceName = res.deviceName
        this.deviceUseForm.deviceModel = res.deviceModel
        this.deviceUseForm.deviceNo = res.deviceNo
        this.deviceUseForm.useDate = res.useDate
        this.deviceUseForm.startupTime = res.startupTime
        this.deviceUseForm.shutdownTime = res.shutdownTime
        this.deviceUseForm.runningCondition = res.runningCondition
        this.deviceUseForm.productInfo = res.productInfo
        this.deviceUseForm.operator = res.operator
        this.deviceUseForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDeviceUseList(this.searchForm).then(res => {
        this.deviceUsePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDeviceUseList(this.searchForm).then(res => {
        this.deviceUsePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDeviceUseList(this.searchForm).then(res => {
        this.deviceUsePage = res
      })
    }
  }
}
</script>

<style>
</style>
